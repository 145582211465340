/* layout.css */
.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  padding: 3rem;
  margin: 30px;
}

.main-children {
  flex: 1;
  display: flex;
  flex-direction: column;
}

