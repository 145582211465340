:root {
  --container-padding: 15px;
  --container-max-width: 1000px;
  --card-padding: 20px;
  --grid-gap: 20px;
  --icon-padding: 30px;
  --list-item-padding-left: 25px;
  --list-dot-size: 8px;
  --responsive-gap: clamp(15px, 2vw, 20px); /* Responsive gap based on viewport width */
}

.container {
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

#service-page-details {
  background-color: #f5f5f5;
  padding: 15px 0;
}

.service-page-card {
  background: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: var(--card-padding);
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: var(--responsive-gap);
}

.icon-and-services {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: var(--responsive-gap);
}

.service-icon {
  /* Assuming FontAwesome or similar for icons */
  display: flex; /* Center the icon horizontally */
  justify-content: center;
  font-size: clamp(2rem, 5vw, 3rem); /* Dynamically adjust icon size */
  padding: clamp(10px, 2.5vw, 30px); /* Adjust padding around the icon */
  color: var(--accent-color);
  transition: transform 0.4s ease-in-out;
}

.service-icon:hover {
  transform: rotate(360deg);
}

.service-title {
  margin-top: 10px; /* Add space between the icon and the title */
  font-size: clamp(1.2rem, 3vw, 1.5rem); /* Responsive font size for the title */
}

h2 {
  color: var(--secondary-color);
  margin: 0 0 10px;
}

p {
  color: var(--main-text-color);
  line-height: 1.6;
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.services-list li {
  position: relative;
  padding-left: var(--list-item-padding-left);
  margin-bottom: 10px;
  line-height: 1.6;
  color: var(--secondary-color);
}

.services-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: var(--list-dot-size);
  height: var(--list-dot-size);
  background: var(--secondary-color);
  border-radius: 50%;
}

/* Media queries */
@media (max-width: 480px) {
  :root {
    --container-padding: 10px;
    --card-padding: 15px;
    --icon-padding: 20px;
    --grid-gap: 15px;
    --font-size-title: 1.25rem;
    --font-size-text: 0.875rem;
  }

  .container, .service-page-card {
    padding: var(--container-padding);
  }

  .service-icon {
    padding: var(--icon-padding);
  }

  .icon-and-services {
    grid-template-columns: 1fr; /* Stack icon and content vertically on small screens */
    gap: var(--grid-gap);
  }

  h2 {
    font-size: var(--font-size-title);
  }

  p, .services-list li {
    font-size: var(--font-size-text);
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --container-padding: 15px;
    --card-padding: 18px;
    --icon-padding: 25px;
    --grid-gap: 18px;
    --font-size-title: 1.4rem;
    --font-size-text: 0.95rem;
  }

  .icon-and-services {
    gap: var(--grid-gap);
  }

  h2 {
    font-size: var(--font-size-title);
  }

  p, .services-list li {
    font-size: var(--font-size-text);
  }
}

@media (min-width: 769px) {
  /* Adjustments for larger screens can be placed here if needed,
     but may not be necessary if base styles are designed for desktop-first. */
}