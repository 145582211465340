/* Root CSS variables for theme consistency */
:root {
  --primary-color: #a67f00;
  --secondary-color: #002855;
  --background-color: #fff;
  --accent-color: #ac0082;
  --main-text-color: #2a2929;
  --hover-accent-color: rgba(0, 0, 0, 0.08);
  --navbar-height: 60px;
  --darkened-primary-color: #594d00;
  --darkened-secondary-color: #001c3a;
  --transparent-secondary-color: rgba(0, 40, 85, 0.9);
  --soft-dark-shadow: rgba(0, 0, 0, 0.1);
  --soft-white-shadow: rgba(255, 255, 255, 0.3);
  --soft-gold-shadow: rgba(166, 127, 0, 0.2);
  --base-font-size: 1rem;
  --heading-font-family: 'Montserrat', sans-serif;
  --body-font-family: 'Roboto', sans-serif;
  --heading-font-weight: 700;
  --base-line-height: 1.6;
}

/* Base styles for body, focusing on font properties and background */
body {
  font-family: var(--body-font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  background: var(--background-color);
  margin: 0;
  color: var(--main-text-color); /* Ensures text color consistency */
}

/* Centered App container with responsive font size */
.App {
  text-align: center;
  font-size: calc(1rem + 1vmin); /* Adjust font size based on viewport */
}

/* Dynamic logo sizing and rotation animation */
.App-logo {
  height: 20vmin;
  animation: App-logo-spin infinite 20s linear;
}

/* Header styling with flexible layout */
.App-header {
  background-color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
}

/* Styling links with accent color */
.App-link {
  color: var(--accent-color);
  text-decoration: none; /* Remove underlines from links for clarity */
}

/* Enhancing heading elements with responsive fonts and dynamic underlines */
h2,
h3,
h4,
h5,
.brand-heading,
.intro .intro-body .intro-text {
  font-family: var(--heading-font-family);
  color: var(--primary-color);
  font-weight: var(--heading-font-weight);
}

h2 {
  font-size: clamp(2rem, 5vw, 3.5rem); /* Responsive font sizing */
}

h2:hover::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: var(--primary-color);
  transition: all 0.2s ease-in-out;
}

.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

/* Keyframe for logo spin animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media queries for adaptive layouts across different screen sizes */
@media only screen and (max-width: 480px) {
  .services-grid, .navbar-collapse {
    grid-template-columns: 1fr; /* Stack elements for mobile */
    display: flex; /* Ensure navbar is usable on mobile */
  }
}

@media (min-width: 481px) {
  .services-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adaptive grid layout */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Accessibility enhancements */
:focus {
  outline: 3px solid var(--accent-color); /* Highlight focus for keyboard navigation */
}

/* Ensure text remains legible for users with color vision deficiencies */
.has-text-warning {
  color: var(--soft-gold-shadow); /* Soft gold color for warnings */
}
