
/* RETURN TO TOP */
#return-to-top {
    position: relative;
    bottom: 20px;
    right: 20px;
    background: var(--primary-color); /* Using primary color for button */
    width: 50px; /* Size of the button */
    height: 50px;
    display: flex; /* Using flex to center the icon */
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 50%; /* Circle shape */
    transition:
            background-color 0.3s ease,
            box-shadow 0.3s ease,
            transform 0.3s ease;
    z-index: 1050; /* High z-index to ensure it's above other elements */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

#return-to-top {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    background-color: var(--accent-color); /* Accent color for the button */
    color: var(--background-color);
    padding: 1rem; /* Padding for size */
    border-radius: 50%; /* Rounded shape */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px var(--soft-dark-shadow); /* Shadow for depth */
}

#return-to-top span {
    color: var(--primary-color); /* Contrast color for the icon */
    position: relative; /* Position relative to enable precise positioning */
    left: 16px; /* Center the icon horizontally */
    top: 13px; /* Center the icon vertically */
    font-size: 19px; /* Size of the icon */
    transition: all 0.3s ease; /* Transition for hover effects */
}

/* This defines a filter that will be used for the shadow */
.shadow-filter {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
}


/* Hover states for return to top button */
#return-to-top:hover {
    background: var(--hover-accent-color); /* Color on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Elevated shadow on hover */
    transform: translateY(-3px); /* Slight rise on hover */
}

#return-to-top:hover span {
    color: var(--primary-color);
    top: 5px; /* Move icon up slightly on hover */
}

.return-to-top-icon:hover,
.return-to-top-icon:active {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5)); /* This adds the shadow on hover and click */
}

.return-to-top {
    position: fixed;
    right: 10px; /* Adjust as needed to match your design */
    bottom: 10px; /* Adjust as needed for spacing from the bottom */
    z-index: 1050; /* Ensure it's above other elements */
}

.return-to-top-icon {
    color: var(--primary-color); /* Icon color */
    cursor: pointer; /* To indicate the icon is clickable */
    font-size: 45px; /* Adjust icon size as needed */
}
/* END RETURN TO TOP */