/* Services Section Styles */
#services {
  margin: 0;
  height: auto;
  text-align: center;
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  color: var(--primary-color);
  transition: transform 0.3s ease;
}

#services h2 {
  font-weight: 700;
  color: var(--primary-color);
  margin: 10px;
  font-size: clamp(1.5rem, 3vw, 2rem);
}

#services h3 {
  color: var(--secondary-color);
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
  font-weight: bold;
}

.ct-services {
  margin-top: 20px;
}

.service-card {
  border-radius: 8px;
  background: var(--background-color);
  box-shadow: var(--soft-dark-shadow);
  padding: 5% 7%;
  margin: 3%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-color: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  height: 80%;
}

#services .services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 5% 0 0 0;
}

.service-card .icon-wrapper {
  display: inline-block;
  padding: 1rem;
  color: var(--primary-color);
  height: 20%;
}

.service-card:hover .icon-wrapper {
  transform: rotate(360deg);
  transition: transform 0.4s ease;
}

.card-title {
  display: flex;
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  color: var(--primary-color);
  min-height: 15%;
  align-items: center;
}

.card-text {
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: #666;
  height: 45%;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services-cta {
  text-align: center;
  margin-top: 2rem;
}

.services-cta .get-started-btn {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  border-radius: 5px;
  padding: 0.9375rem 1.875rem;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none;
  margin-top: 2rem;
}

.services-cta .get-started-btn:hover {
  background-color: var(--darkened-primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px var(--soft-dark-shadow);
}

.learn-more-btn {
  background-color: var(--accent-color);
  color: white;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}

.learn-more-btn:hover,
.learn-more-btn:focus {
  background-color: var(--transparent-secondary-color);
  outline: none;
}

@media (max-width: 768px) {
  #services .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  #services .services-grid {
    grid-template-columns: 1fr;
  }
}
