.intro-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem; /* Adjusted for a mobile-first approach */
    min-height: 100vh;
    background: url("../assets/images/black_ceo_desk_innovation.webp") no-repeat center center / cover;
    color: var(--background-color);
}

.intro-text-box {
    position: absolute;
    top: 50%; /* Centered vertically */
    left: 50%;
    transform: translate(-50%, -50%); /* Adjust for center alignment */
    max-width: 90%; /* Allows for padding on smaller devices */
    padding: 2rem;
    background: linear-gradient(135deg, rgba(10, 20, 40, 0.6) 0%, rgba(10, 20, 40, 0.3) 100%);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    border-left: 3px solid var(--primary-color);
    box-shadow: 0 4px 8px var(--soft-dark-shadow);
}

.brand-heading {
    font-size: clamp(1.5rem, 5vw, 3rem); /* Responsive font size */
    margin-bottom: 0.5rem;
    color: var(--background-color);
}

.intro-text {
    font-size: clamp(1rem, 2.5vw, 1.25rem); /* Responsive font size */
    margin-bottom: 1rem;
    color: var(--background-color);
}

/* Adjust layout for tablets and desktops */
@media (min-width: 768px) {
    .intro-text-box {
        max-width: 40%; /* Re-adjust max-width for larger screens */
    }

    .intro-section p.intro-text {
        max-width: 80%;
    }
}
