:root {
    --navbar-background: linear-gradient(
            135deg,
            rgba(0, 40, 85, 0.9) 60%,
            rgba(0, 40, 85, 0.7) 80%
    );
    --dropdown-offset: 10px;
    --navbar-padding: 1rem;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--navbar-padding);
    background: var(--navbar-background);
    backdrop-filter: blur(6px);
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 4px solid var(--primary-color);
}

.navbar-logo img {
    height: calc(var(--navbar-height) * 0.8);
}

.navbar-toggler {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--background-color);
    cursor: pointer;
    position: relative; /* Keep this relative */
    z-index: 2;
}

.fa-circle-caret-down {
    display: inline-block; /* Ensures the icon behaves as a block-level element */
    transition: transform 0.3s ease;
}

/* Apply rotation directly to the icon */
.navbar-toggler.active .fa-circle-caret-down {
    transform: rotate(180deg);
}

/* Menu container */
.navbar-menu {
    display: none;
}

/* Toggle display of dropdown when active */
.navbar-toggler.active + .navbar-dropdown {
    display: block;
}

.navbar-dropdown {
    display: none; /* Start hidden */
    position: absolute;
    right: 0;
    top: calc(100% + var(--dropdown-offset)); /* Offset from the bottom of the navbar */
    background-color: var(--primary-color);
    border-radius: 5px;
    box-shadow: 0 4px 8px var(--soft-dark-shadow);
    padding: 0.5rem 0;
    z-index: 1;
    width: max-content; /* Ensure it doesn't stretch full width */
}

.dropdown-item {
    color: var(--background-color);
    text-decoration: none;
    display: block;
    padding: 0.9rem 1rem;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: var(--darkened-primary-color);
}

.btn-get-started {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.btn-get-started:hover {
    background-color: var(--darkened-primary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
    }

    .navbar-menu.active,
    .navbar-dropdown {
        width: 100%; /* Ensures dropdown stretches to full width on mobile */
    }
}

@media (min-width: 769px) {
    .navbar {
        padding: 0.5rem 2rem;
    }
}
