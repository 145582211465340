/* General Footer Styles */
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--background-color);
    background-color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 var(--soft-white-shadow);
    margin: 0;
    padding: .10rem 0; /* Added padding for consistency */
}

/* Company Logo */
.footer-logo {
    display: flex;
    align-items: center; /* Align vertically */
    padding: .1rem; /* Simplified padding */
}

.footer-logo img {
    width: 50px; /* Set your desired width */
    height: auto; /* Keep the aspect ratio of the image */
}

/* Social Icons, Service Links, and Contact Styles */
.footer-socials,
.footer-service-link,
.footer-contact {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    justify-content: center; /* Center-align for better mobile appearance */
    gap: .5rem;
    padding: .1rem;
}

/* Bottom Row Styles */
.footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--background-color);
}

/* Consistent font size for all elements in the footer */
.footer-bottom a,
.footer-bottom p,
.footer-copy,
.footer-legal {
    font-size: .9em; /* Adjusted for consistency */
    text-align: center; /* Center-align text for mobile screens */
    color: var(--background-color);
}


/* Link Styles */
footer a {
    color: var(--background-color);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

/* Adjusts the hover effect for links */
footer a:hover {
    color: var(--primary-color);
}

/* Responsive adjustments for Tablets */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .footer-logo img {
        width: 50px;
    }
}

/* Responsive adjustments for Mobile Devices */
@media (max-width: 480px) {
    .footer-grid {
        grid-template-columns: 1fr;
    }
    footer {
        padding: 1rem;
    }
    .footer-bottom {
        flex-direction: column;
    }
}