/* Additional styles for .get-started section integrated with app.css */
.get-started-container {
    background-color: var(--background-color);
    padding: 50px 20px;
    text-align: center;
}

.get-started-card {
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--soft-dark-shadow);
    padding: 2rem;
    max-width: 700px;
    width: 100%;
    margin: 2rem auto 140px;
    text-align: center;
}

.get-started-icon {
    display: inline-block; /* Adjust to block if necessary, but inline-block should suffice for center alignment */
    width: 100%; /* Ensures the element takes full width for centering */
    color: var(--secondary-color);
    margin-bottom: 1rem;
    transition: transform 0.4s ease-in-out;
    /* Center the icon */
    text-align: center;
}


.get-started-icon:hover {
    transform: rotate(360deg);
}

.get-started-title,
.get-started-text {
    color: var(--primary-color);
    margin-bottom: 30px;
    font-size: calc(1rem + 1vmin); /* Responsive font size */
}

.get-started-text {
    color: var(--main-text-color);
    margin-bottom: 50px;
}

.get-started-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.get-started-form input,
.get-started-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    width: calc(100% - 22px);
    box-sizing: border-box;
}

.get-started-form button.submit-btn {
    display: inline-block; /* Allows the width to be content-dependent */
    margin: 0 auto; /* Center the button */
    padding: 1rem 2rem; /* Increase horizontal padding */
    border-radius: 3rem; /* Rounded borders */
    border: none; /* No border */
    background-color: var(--accent-color); /* Button color */
    color: var(--background-color); /* Text color */
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: calc(0.6rem + 1vmin); /* Responsive font size */
}

.get-started-form button.submit-btn:hover {
    background-color: var(--secondary-color);
    border-color: var(--primary-color);
    border-style: outset;
    border-width: medium;
}

/* Responsive adjustments with consistency to app.css */
@media (max-width: 768px) {
    .get-started-container {
        padding: 40px 10px;
    }

    .get-started-title,
    .get-started-text {
        font-size: calc(0.8rem + 2vmin); /* Adjust font size for better readability */
    }

    .get-started-form {
        max-width: 90%;
    }

    .get-started-icon {
        font-size: 2.5rem; /* Adjust icon size on tablets */
    }
}

@media (max-width: 480px) {
    .get-started-card {
        margin: 1rem auto 100px;
        padding: 1.5rem;
    }

    .get-started-title,
    .get-started-text,
    .get-started-form button.submit-btn {
        font-size: calc(0.75rem + 2vmin); /* Further adjust font size for small devices */
    }
    .get-started-icon {
        font-size: 2rem; /* Adjust icon size on mobile phones for better fit */
    }
}