:root {
    --font-family-base: 'Roboto', sans-serif;
    --font-family-highlight: 'Montserrat', sans-serif;
    --base-padding: 2rem; /* Base padding */
    --base-font-size: 1rem; /* Base font size */
    --section-padding: 2rem; /* Padding for sections */
    --text-color: #333; /* Fallback text color */
    --list-indent: 2rem; /* Indentation for list items */
    --focus-outline-offset: 3px; /* Outline offset for focusable elements */
}

/* Base layout styles with responsive padding */
body#privacy-policy-page {
    font-family: var(--font-family-base);
    color: var(--secondary-color);
    background-color: var(--background-color);
    padding: var(--base-padding);
    font-size: var(--base-font-size); /* Ensure base font size is applied */
}

/* Styling for the header of the privacy policy */
header.intro-policy {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: clamp(1rem, 6vw, var(--base-padding)) 0; /* Responsive vertical padding */
}

header.intro-policy h1 {
    margin-bottom: 1rem;
    font-family: var(--font-family-highlight);
    font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size for main title */
}

/* Style for each section of the policy for clear segmentation */
section {
    padding: 0 var(--section-padding);
}

section h2 {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: clamp(1.125rem, 3.5vw, 1.5rem); /* Responsive font size for section titles */
}

/* General text styles for good readability */
section p, section ul li {
    font-size: var(--base-font-size); /* Apply base font size for consistency */
    line-height: 1.6;
    text-align: left;
    color: var(--text-color);
}

section ul {
    list-style-type: disc;
    margin-left: var(--list-indent);
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#return-to-top:hover {
    background-color: var(--transparent-secondary-color);
}

:focus {
    outline-offset: var(--focus-outline-offset);
}

/* Responsive design adjustments for different screen sizes */
@media (max-width: 480px) {
    :root {
        --base-font-size: 0.875rem; /* Smaller base font size for smaller screens */
    }
    body#privacy-policy-page {
        padding: 1rem; /* Smaller padding for smaller screens */
    }
    header.intro-policy h1, section h2 {
        font-size: smaller; /* Smaller font size for smaller screens */
    }
    section {
        padding: 0 1rem; /* Smaller padding for smaller screens */
    }
    section ul {
        margin-left: 1.5rem; /* Smaller left margin for list */
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    /* Adjustments for tablet viewports */
}

@media (min-width: 769px) {
    /* Desktop-specific adjustments */
}
