body#terms-conditions-page {
    font-family: 'Roboto', sans-serif;
    color: var(--secondary-color);
    background-color: var(--background-color);
    padding: 2rem; /* Consider using `vh` or `vw` for more fluid padding */
}

.condition-brand-heading, .intro-text {
    padding-left: 2rem; /* Use `rem` for scalability */
}

header.conditions-intro {
    background-color: var(--primary-color);
    backdrop-filter: blur(5px);
    color: var(--background-color);
    padding: 3rem 0; /* Increased responsiveness with `vh` for vertical padding could be considered */
}

header.conditions-intro h1 {
    font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size with clamp */
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
}

section {
    padding: 0 2rem; /* Consistency with `rem` for scalability */
}

section h2 {
    font-size: clamp(1.25rem, 3.5vw, 1.75rem); /* Responsive section titles */
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    font-weight: 700;
}

section p, section ul {
    line-height: 1.6;
    text-align: left;
}

section ul {
    list-style-type: disc;
    margin-left: 2rem; /* Consider `rem` for consistency */
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#return-to-top:hover {
    background-color: var(--transparent-secondary-color);
}

:focus {
    outline-offset: 3px;
}

/* Responsive design adjustments for different screen sizes */
/* Adjustments for mobile devices */
@media only screen and (max-width: 480px) {
    body#terms-conditions-page, section {
        padding: 1rem; /* Decrease padding for smaller screens */
    }

    header.conditions-intro h1, section h2 {
        font-size: clamp(1.25rem, 5vw, 1.5rem); /* Smaller titles on mobile */
    }
}

/* Tablet adjustments */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    body#terms-conditions-page {
        padding: 2.5rem; /* Moderate padding for medium screens */
    }

    section h2 {
        font-size: 1.5em; /* Increase for readability */
    }
}

/* Larger screens */
@media only screen and (min-width: 769px) {
    body#terms-conditions-page {
        padding: 3rem; /* Increased padding for larger screens */
    }

    section h2 {
        font-size: 1.75em; /* Enhanced emphasis on section titles */
    }
}