.about-section {
    text-align: center;
    padding: 4rem 0; /* Kept as is, consider vh for vertical padding if needed */
    background: #f5f5f5;
    color: #333;
    position: relative;
}

.section-heading {
    font-size: clamp(2rem, 5vw, 2.5rem); /* Adjust font size responsively */
    color: var(--primary-color);
    margin-bottom: 2rem;
}

.about-content p {
    font-size: clamp(1rem, 2.5vw, 1.2rem); /* Responsive font scaling */
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto 3rem;
}

.about-highlights {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.highlight h3 {
    font-size: clamp(1.25rem, 3vw, 1.5rem); /* Responsive font scaling */
    color: var(--secondary-color);
}

.highlight p {
    font-size: clamp(0.9rem, 2vw, 1rem); /* Responsive font scaling */
    color: #666;
    max-width: 250px;
}

.veteran-logo-box {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 3;
}

.veteran-logo {
    width: clamp(100px, 10vw, 120px); /* Responsive width for the logo */
    height: auto;
}


/* Responsive design adjustments */
@media (max-width: 768px) {
    .about-highlights {
        flex-direction: column;
        align-items: center; /* Center items when stacked */
    }

    .about-section h2.section-heading, .about-content p, .highlight h3, .highlight p {
        font-size: 1rem; /* Use specific values for better control */
    }

    /* Increase touch targets */
    .highlight {
        padding: 15px; /* Increased padding for easier interaction */
        margin: 10px 0; /* More vertical space between items */
    }

    .veteran-logo-box {
        padding: 1rem; /* Increased padding for visibility */
    }

    .veteran-logo {
        width: 80px; /* Adjust based on preference */
    }
}

@media (max-width: 480px) {
    .veteran-logo {
        width: 60px; /* Further reduce for smaller devices */
    }
}